import React from "react";
import "react-toastify/dist/ReactToastify.css";
// import styled from "styled-components";

const App = () => (
  <main
    className="App"
    style={{
      textAlign: "center",
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      flexWrap: "wrap",
    }}
  >
    <img
      src={`${process.env.PUBLIC_URL}/images/origyn-foundation-black.png`}
      alt="logo"
      width="300px"
      style={{
        display: "block",
        margin: "auto",
      }}
    />
    <div style={{ width: "100%" }}>
      <h4>Coming soon...</h4>
    </div>
  </main>
);

export default App;
